import { LANDING_DESCRIPTION, LANDING_TITLE } from '@/utils/messages';
import {
  clearLastAuthEmailFromUrl,
  clearTrackIdFromStorage,
  getLastAuthEmailFromUrl,
  getTrackIdFromStorage,
  getTrackIdFromUrl,
  saveTrackIdToStorage,
} from '@/utils/utils';
import { default as SuccessIcon } from '@mui/icons-material/CheckCircleRounded';
import { PasswordlessLogin } from '@verifime/cognito-passwordless-auth';
import { Landing } from '@verifime/components';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function LoginPage() {
  const router = useRouter();

  useEffect(() => {
    // Sign in by magic link,
    // it may include the trackId if the link is generated for invitation
    const trackId = getTrackIdFromUrl();
    if (trackId) {
      saveTrackIdToStorage(trackId);
    }
  }, []);

  const signInRedirect = () => {
    router.push('/credentialSetup');
  };

  const getMagicLinkReriectUrl = async () => {
    const origin = location.origin;
    let trackId = getTrackIdFromUrl();
    if (!trackId) {
      trackId = getTrackIdFromStorage();
    }
    if (!trackId) {
      return origin;
    }

    return `${origin}?trackId=${trackId}`;
  };

  return (
    <Landing title={LANDING_TITLE} description={LANDING_DESCRIPTION}>
      <PasswordlessLogin
        signInRedirect={signInRedirect}
        getMegicLinkRedirectUri={getMagicLinkReriectUrl}
        onSignInAsDifferentUser={() => {
          clearTrackIdFromStorage();
          clearLastAuthEmailFromUrl();
        }}
        initialEmail={getLastAuthEmailFromUrl()}
      >
        <SuccessIcon color="success" />
      </PasswordlessLogin>
    </Landing>
  );
}
